import React, { useState, useEffect, useRef } from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import { CircularProgress, MenuItem, Select, IconButton, Modal } from '@mui/material';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import moment from 'moment';
import zoomPlugin from 'chartjs-plugin-zoom';
import 'chartjs-adapter-date-fns';
import useZoomLevelFormat from './useZoomLevelFormat';
import CloseIcon from '@mui/icons-material/Close';

const WeatherChart = ({ weekWeatherData = [] }) => {
  const [selectedVariable, setSelectedVariable] = useState('temperature_ext');
  const [chartData, setChartData] = useState(null);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const { timestampFormat, setZoomLevel } = useZoomLevelFormat();
  const chartRef = useRef(null);

  const variables = [
    { label: 'Temperature Internal', value: 'temperature_int', unit: '°C' },
    { label: 'Humidity Internal', value: 'humidity_int', unit: '%' },
    { label: 'Pressure Internal', value: 'pressure_int', unit: 'hPa' },
    { label: 'Temperature External', value: 'temperature_ext', unit: '°C' },
    { label: 'Humidity External', value: 'humidity_ext', unit: '%' },
    { label: 'Pitch', value: 'pitch', unit: '°' },
    { label: 'Roll', value: 'roll', unit: '°' },
    { label: 'Wind Speed', value: 'wind_speed', unit: 'm/s' },
    { label: 'Wind Direction', value: 'wind_direction', unit: '°' },
    { label: 'Wind Gust Speed', value: 'wind_gust_speed', unit: 'm/s' },
    { label: 'Wind Gust Direction', value: 'wind_gust_direction', unit: '°' },
  ];
  
  const variableColors = {
    temperature_int: 'rgba(255, 99, 132, 1)', // Red
    humidity_int: 'rgba(54, 162, 235, 1)',   // Blue
    pressure_int: 'rgba(75, 192, 192, 1)',   // Green
    temperature_ext: 'rgba(255, 159, 64, 1)', // Orange
    humidity_ext: 'rgba(153, 102, 255, 1)',  // Purple
    pitch: 'rgba(255, 206, 86, 1)',          // Yellow
    roll: 'rgba(201, 203, 207, 1)',          // Grey
    wind_speed: 'rgba(54, 162, 235, 1)',     // Light Blue
    wind_direction: 'rgba(255, 99, 132, 1)', // Light Red
    wind_gust_speed: 'rgba(75, 192, 192, 1)',// Light Green
    wind_gust_direction: 'rgba(153, 102, 255, 1)', // Light Purple
    flag: 'rgba(255, 0, 0, 1)', // Red for flagged points
  };

  const flags = {
    '-10': 'Suppressed',
    '-1': 'Missing',
    '0': 'Error',
    '10': 'Doubtful',
    '15': 'Suspect/Warning',
    '20': 'Inconsistent',
    '100': 'Acceptable/Passed'
  };

  useEffect(() => {
    if (Array.isArray(weekWeatherData) && weekWeatherData.length > 0) {
      const timestamps = weekWeatherData.map(entry => moment(entry.timestamp).format(timestampFormat));
      const dataValues = weekWeatherData.map(entry => entry.elements[selectedVariable]?.value || 0);
      const flaggedData = weekWeatherData.map(entry => 
        entry.elements[selectedVariable]?.qc_value !== undefined ? entry.elements[selectedVariable]?.value : null
      );

      setChartData({
        labels: timestamps,
        datasets: [
          {
            label: `${variables.find(v => v.value === selectedVariable).label} (${variables.find(v => v.value === selectedVariable).unit})`,
            data: dataValues,
            borderColor: variableColors[selectedVariable],
            fill: false,
            pointBackgroundColor: dataValues.map((value, index) => 
              flaggedData[index] !== null ? variableColors.flag : variableColors[selectedVariable]
            ),
            pointStyle: dataValues.map((value, index) => 
              flaggedData[index] !== null ? 'rectRot' : 'circle'
            ),
          },
        ],
      });
    } else {
      setChartData(null);
    }
  }, [selectedVariable, weekWeatherData, timestampFormat]);

  const options = {
    plugins: {
      zoom: {
        zoom: {
          wheel: {
            enabled: true,
          },
          mode: 'x',
          onZoomComplete: ({ chart }) => {
            const minTime = chart.scales.x.min;
            const maxTime = chart.scales.x.max;
            const zoomLevel = maxTime - minTime;
            setZoomLevel(zoomLevel);
          },
        },
        pan: {
          enabled: true,
          mode: 'xy',
          threshold: 20,
        },
      },
      tooltip: {
        callbacks: {
          label: function(tooltipItem) {
            const dataIndex = tooltipItem.dataIndex;
            const qcValue = weekWeatherData[dataIndex].elements[selectedVariable]?.qc_value;
            const comment = weekWeatherData[dataIndex].elements[selectedVariable]?.comment || '';
            const flagMeaning = flags[qcValue] || '';
            const unit = variables.find(v => v.value === selectedVariable).unit;
            const variableName = variables.find(v => v.value === selectedVariable).label;
            return `${variableName}: ${tooltipItem.raw} ${unit} ${flagMeaning ? `(${flagMeaning})` : ''} ${comment ? `(${comment})` : ''}`;
          }
        }
      }
    },
  };

  const handleFullScreen = () => {
    setIsFullScreen(true);
  };

  return (
    <div>
      <Select
        value={selectedVariable}
        onChange={(e) => setSelectedVariable(e.target.value)}
        variant="outlined"
        style={{ marginBottom: '16px' }}
      >
        {variables.map(variable => (
          <MenuItem key={variable.value} value={variable.value}>
            {variable.label}
          </MenuItem>
        ))}
      </Select>
      {chartData ? (
        <div style={{ position: 'relative' }}>
          <Line ref={chartRef} data={chartData} options={options} plugins={[zoomPlugin]} />
          <IconButton 
            onClick={handleFullScreen} 
            style={{ position: 'absolute', top: '0px', right: '10px' }}
            size="small"
          >
            <FullscreenIcon />
          </IconButton>
        </div>
      ) : (
        <CircularProgress />
      )}
      <Modal open={isFullScreen} onClose={() => setIsFullScreen(false)}>
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          outline: 'none'
        }}>
          <div style={{
            backgroundColor: 'white',
            padding: '20px',
            borderRadius: '8px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
            width: '90%',
            height: '90%',
            position: 'relative'

          }}>
            <IconButton 
              onClick={() => setIsFullScreen(false)} 
              style={{ position: 'absolute', top: '10px', right: '10px' }}
              size="small"
            >
              <CloseIcon />
            </IconButton>
           
            <Line data={chartData} options={options} plugins={[zoomPlugin]} />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default WeatherChart;