import { useState, useEffect } from 'react';

const useZoomLevelFormat = () => {
  const [zoomLevel, setZoomLevel] = useState(0);
  const [timestampFormat, setTimestampFormat] = useState('MMM Do'); // Default format

  // Update timestamp format based on zoom level
  useEffect(() => {
    if (zoomLevel < 2) { 
      setTimestampFormat('MMM Do');
    } else {
      setTimestampFormat('MMM Do, h a');
    }
  }, [zoomLevel]);

  return { timestampFormat, setZoomLevel };
};

export default useZoomLevelFormat;