import { useState, useEffect } from "react";
import axios from "axios";

const API_BASE_URL = "https://client-api.silanga.ca/aws";
const API_KEY = process.env.REACT_APP_API_TOKEN;
export const useCurrentWeatherData = (stationId) => {
	const [currentData, setCurrentData] = useState(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	useEffect(() => {
		const fetchCurrentData = async () => {
			if (!stationId) {
				setLoading(false);
				return;
			}

			setLoading(true);
			setError(null);

			try {
				const response = await axios.get(API_BASE_URL, {
					params: { uid: stationId },
					headers: { "x-api-key": API_KEY },
				});
				setCurrentData(response.data[0]);
			} catch (err) {
				setError("Error fetching current weather data.");
				console.error("Error fetching current weather data:", err);
			} finally {
				setLoading(false);
			}
		};

		fetchCurrentData();
	}, [stationId]);

	return { currentData, loading, error };
};

export const useLastWeekWeatherData = (stationId) => {
	const [lastWeekData, setLastWeekData] = useState(null);
	const [weekDataLoading, setLoading] = useState(true);
	const [weekDataError, setError] = useState(null);

	useEffect(() => {
		const fetchLastWeekData = async () => {
			if (!stationId) {
				setLoading(false);
				return;
			}

			setLoading(true);
			setError(null);

			try {
				const response = await axios.get(API_BASE_URL, {
					params: { uid: stationId, last_week: true },
					headers: { "x-api-key": API_KEY },
				});
				setLastWeekData(response.data);
			} catch (err) {
				setError("Error fetching last week weather data.");
				console.error("Error fetching last week weather data:", err);
			} finally {
				setLoading(false);
			}
		};

		fetchLastWeekData();
	}, [stationId]);

	return { lastWeekData, weekDataLoading, weekDataError };
};

export const useAllWeatherData = (stationId, limit, offset) => {
	const [allData, setAllData] = useState(null);
	const [allDataTotalRecords, setallDataTotalRecords] = useState(0);
	const [allDataLoading, setLoading] = useState(true);
	const [allDataError, setError] = useState(null);

	useEffect(() => {
		const fetchAllData = async () => {
			if (!stationId) {
				setLoading(false);
				return;
			}

			setLoading(true);
			setError(null);

			try {
				const response = await axios.get(API_BASE_URL, {
					params: {
						uid: stationId,
						all_data: true,
						limit: limit,
						offset: offset,
					},
					headers: {
						"x-api-key": API_KEY,
					},
				});
				setAllData(response.data.data);
				setallDataTotalRecords(response.data.pagination.total_records)
				if(response.data.statusCode === 400){
					// setError(JSON.parse(response.data.body).error + JSON.parse(response.data.body).details)
					setError(JSON.parse(response.data.body).error)

				}

			} catch (err) {
				setError("Error fetching All weather data.");
				console.error("Error fetching All weather data:", err);
			} finally {
				setLoading(false);
			}
		};

		fetchAllData();
	}, [stationId,limit,offset]);

	return { allData, allDataLoading, allDataError ,allDataTotalRecords};
};
