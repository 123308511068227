import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ThemeProvider, createTheme, CssBaseline, Box } from '@mui/material';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import StationDashboard from './components/StationDashboard/StationDashboard';
import Home from './components/Home/Home';
import 'leaflet/dist/leaflet.css';
import theme from './utils/theme'; 
import ScrollToTop from './utils/ScrollToTop';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
      <ScrollToTop />

        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
          <Navbar />
          {/* <Box sx={{ flexGrow: 1, p: 3 }}> */}
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/:stationId" element={<StationDashboard />} />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          {/* </Box> */}
          <Footer />
        </Box>
      </Router>
    </ThemeProvider>
  );
}

export default App;