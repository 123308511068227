import React from 'react';
import { MapContainer, TileLayer, Circle, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { Box, Typography } from '@mui/material';

function StationMap({ stationId, latitude, longitude, cep ,metadata}) {
  return (
    <Box sx={{ height: '400px', width: '100%' }}>
      <Typography variant="h6" gutterBottom>
        Station Location: {metadata.name} ( {metadata.Community} )
      </Typography>
      <MapContainer 
        center={[latitude, longitude]} 
        zoom={13} 
        style={{ height: 'calc(100% - 40px)', width: '100%' }}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <Circle center={[latitude, longitude]} radius={cep} color="red">
          <Popup>
            Latitude: {latitude}<br />
            Longitude: {longitude}<br />
            CEP: {cep} meters
          </Popup>
        </Circle>
      </MapContainer>
    </Box>
  );
}

export default StationMap;