import React, { useState } from "react";
import {
	Typography,
	Box,
	Grid,
	Paper,
	MenuItem,
	TextField,
	IconButton,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useMediaQuery } from "@mui/material";
import DeviceHubIcon from "@mui/icons-material/DeviceHub"; // Example icon for type
import HomeIcon from "@mui/icons-material/Home"; // Example icon for community
import { Link } from "react-router-dom";
import { stationMetaData } from "../../utils/rawData"; // Importing stationMetaData array
import { getStatusColor } from "../../utils/rawFunction"; // Importing utility function

const AllStationHomeList = () => {
	const [filter, setFilter] = useState("");
	const [CommunityFilter, setCommunityFilter] = useState("");

	const filteredstationMetaData = stationMetaData.filter((app) => {
		return (
			app.name.toLowerCase().includes(filter.toLowerCase()) &&
			(CommunityFilter === "" || app.Community === CommunityFilter)
		);
	});

	const isSmallScreen = useMediaQuery("(max-width:600px)");

	return (
		<Box sx={{ p: 2, maxWidth: "100%", margin: "auto", overflow: "auto" }}>
			<Typography
				variant="h5"
				gutterBottom
				align="center"
				sx={{ mb: 2, fontWeight: "normal" }}
			>
				All Stations
			</Typography>
			<Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
				<TextField
					label="Search by Name"
					variant="outlined"
					size="small"
					value={filter}
					onChange={(e) => setFilter(e.target.value)}
					sx={{ width: "45%" }}
				/>
				<TextField
					label="Filter by Community"
					variant="outlined"
					size="small"
					select
					value={CommunityFilter}
					onChange={(e) => setCommunityFilter(e.target.value)}
					sx={{ width: "45%" }}
				>
					<MenuItem value="">All</MenuItem>
					<MenuItem value="Arctic Bay">Arctic Bay</MenuItem>
					<MenuItem value="Igloolik">Igloolik</MenuItem>
					<MenuItem value="Pond Inlet">Pond Inlet</MenuItem>
					<MenuItem value="Milne Fiord">Milne Fiord</MenuItem>
					<MenuItem value="Ottawa">Ottawa</MenuItem>
				</TextField>
			</Box>
			<Grid container spacing={3}>
				{filteredstationMetaData.map((app, index) => (
					<Grid item xs={12} key={index}>
						<Paper
							elevation={0}
							sx={{
								p: 2,
								border: "1px solid rgba(0, 0, 0, 0.2)",
								marginBottom: "10px",
								transition: "box-shadow 0.3s ease-in-out",
								"&:hover": {
									boxShadow: "0 4px 5px rgba(44, 44, 0, 0.15)",
								},
							}}
						>
							<Box
								sx={{
									display: "flex",
									justifyContent: "space-between",
									alignItems: "center",
									margin: "10px",
								}}
							>
								<Box sx={{ mb: 1 }}>
									<Typography
										variant="body1"
										component={Link}
										to={`/${app.id}`}
										sx={{
											fontWeight: "bold",
											fontSize: "1.5em",
											mb: 0.5,
											display: "flex",
											alignItems: "center",
											textDecoration: "none", // Remove underline
											color: "inherit", // Use 'inherit' to keep the theme color, or specify your desired color
											"&:hover": {
												color: "black", // Optional: Change color on hover
											},
										}}
									>
										{app.name}
									</Typography>

									<Typography
										variant="body2"
										color="text.secondary"
										sx={{ display: "flex", alignItems: "center" }}
									>
										<LocationOnIcon
											sx={{ fontSize: "1rem", marginRight: "7px" }}
										/>
										<span style={{ marginRight: "15px" }}>{app.location}</span>
										<DeviceHubIcon
											sx={{ fontSize: "1rem", marginRight: "7px" }}
										/>
										<span style={{ marginRight: "15px" }}>{app.type}</span>
										<HomeIcon sx={{ fontSize: "1rem", marginRight: "7px" }} />
										<span>{app.Community}</span>
									</Typography>
								</Box>
								<Typography
									variant="body2"
									color="text.secondary"
									sx={{ fontSize: "1rem", fontWeight: "500" }}
								>
									<span
										style={{
											backgroundColor: getStatusColor(app.status),
											padding: "5px",
											fontSize: "1em",
											borderRadius: "3px",
											marginLeft: "8px",
											fontWeight: "540",
										}}
									>
										{app.status}
									</span>
								</Typography>
							</Box>
						</Paper>
					</Grid>
				))}
			</Grid>
		</Box>
	);
};

export default AllStationHomeList;
