import { Box, Tooltip, Typography } from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import { getCardinalDirection, getQCFlag } from "../../utils/rawFunction";

const WeatherInfoItem = ({ label, value, unit, icon: Icon, element,rotateIcon }) => {
	const qcFlag = getQCFlag(element);
	const cardinalDirection = rotateIcon ? getCardinalDirection(value) : '';
  
	return (
	  <Box
		sx={{
		  borderRadius: "1px",
		  border: "1px solid rgba(5, 5, 5, 0.1)",
		  padding: "12px",
		  display: "flex",
		  flexDirection: "column",
		  alignItems: "center",
		  justifyContent: "center",
		  height: "100%",
		}}
	  >
		<Box sx={{ display: "flex", alignItems: "center", mb: 0.5 }}>
		  {rotateIcon ? (
			<Icon sx={{ mr: 1, transform: `rotate(${value}deg)` }} />
		  ) : (
			<Icon sx={{ mr: 1 }} />
		  )}
		  <Typography
			variant="h4"
			sx={{ fontSize: "clamp(1rem, 2vw, 1.5rem)" }}
		  >
			{value.toFixed(1)}
			{unit}
		  </Typography>
		  {qcFlag && (
			<Tooltip title={`QC Flag: ${qcFlag}`}>
			  <WarningIcon color="warning" sx={{ ml: 1 }} />
			</Tooltip>
		  )}
		</Box>
		<Typography variant="caption">
		  {label} {rotateIcon && `(${cardinalDirection})`}
		</Typography>
	  </Box>
	);
  };
  export default WeatherInfoItem;
