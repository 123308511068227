import React from 'react';
import { Typography, Box, Grid, Paper, IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { stationMetaData } from '../../utils/rawData'; // Import the stationMetaData array
import {getStatusIcon} from '../../utils/rawFunction';
function AllStationsList() {
  return (
    <Box sx={{ p: 2, maxWidth: '100%', margin: 'auto', overflow: 'auto' }}>
      <Grid container spacing={2}>
        {stationMetaData.map((station) => (
          <Grid item xs={12} key={station.id}>
            <Paper 
              elevation={0} 
              sx={{ 
                p: 1, 
                display: 'flex', 
                justifyContent: 'space-between', 
                alignItems: 'center',
                borderBottom: '1px solid #e0e0e0', // Line at the end
                overflow: 'hidden',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ ml: 1 }}>
                {getStatusIcon(station.status)}
                </Box>
                <Box sx={{ ml: 1 }}>
                  <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '0.9rem' }}>
                    {station.name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" sx={{ fontSize: '0.8rem' }}>
                    {station.id}
                  </Typography>
                </Box>
              </Box>
              <IconButton 
                component={Link} 
                to={`/${station.id}`} 
                size="small"
                sx={{ 
                  border: '1px solid #e0e0e0', 
                  borderRadius: '50%', 
                  padding: '4px',
                  '&:hover': {
                    backgroundColor: 'transparent',
                  }
                }}
              >
                <ArrowForwardIosIcon sx={{ fontSize: '0.8rem' }} />
              </IconButton>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default AllStationsList;