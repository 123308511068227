import React from "react";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	TablePagination,
	Tooltip,
	Typography,
} from "@mui/material";
import moment from "moment"; // Import moment

const DataTable = ({
	allData,
	limit,
	offset,
	onLimitChange,
	onOffsetChange,
	total_records,
}) => {
	const handleChangePage = (event, newPage) => {
		const maxPage = Math.floor(total_records / limit);
		if (newPage <= maxPage) {
			onOffsetChange(newPage * limit);
		}
	};

	const handleChangeRowsPerPage = (event) => {
		const newLimit = parseInt(event.target.value, 10);
		onLimitChange(newLimit);
		onOffsetChange(0); // Reset to first page
	};

	const combinedFlags = {
		"-10": { status: "Suppressed", color: "#FF0000" }, // Red
		"-1": { status: "Missing", color: "#FFA500" }, // Orange
		"0": { status: "Error", color: "#808080" }, // Gray
		"10": { status: "Doubtful", color: "#002aff" }, // blue
		"15": { status: "Suspect/Warning", color: "#800080" }, // Purple
		"20": { status: "Inconsistent", color: "#FF69B4" }, // Pink
		"100": { status: "Acceptable/Passed", color: "#008000" }, // Green
	};

	const formatValue = (value, qcValue, comment) => {
		const { status, color } = combinedFlags[qcValue] || {
			status: "Unknown",
			color: "black",
		};
		const tooltipTitle = `QC Flag: ${status}\n , Comment: ${
			comment || "No comment"
		}`;
		return (
			<Tooltip title={tooltipTitle}>
				<span
					style={{ textDecoration: `underline ${color}`, whiteSpace: "nowrap" }}
				>
					{value.toFixed(2)}
				</span>
			</Tooltip>
		);
	};

	// Predefined order of columns
	const columnOrder = [
		{ key: "temperature_int", label: "Temperature (Int)", unit: "°C" },
		{ key: "humidity_int", label: "Humidity (Int)", unit: "%" },
		{ key: "pressure_int", label: "Pressure (Int)", unit: "hPa" },
		{ key: "temperature_ext", label: "Temperature (Ext)", unit: "°C" },
		{ key: "humidity_ext", label: "Humidity (Ext)", unit: "%" },
		{ key: "pitch", label: "Pitch", unit: "°" },
		{ key: "roll", label: "Roll", unit: "°" },
		{ key: "wind_speed", label: "Wind Speed", unit: "m/s" },
		{ key: "wind_direction", label: "Wind Direction", unit: "°" },
		{ key: "wind_gust_speed", label: "Wind Gust Speed", unit: "m/s" },
		{ key: "wind_gust_direction", label: "Wind Gust Direction", unit: "°" },
		{ key: "voltage", label: "Voltage", unit: "V" },
		{ key: "solar", label: "Solar", unit: "W/m²" },
		{ key: "snow_depth", label: "Snow Depth", unit: "cm" },
        { key: "iridium_latitude", label: "Latitude", unit: "°" },
		{ key: "iridium_longitude", label: "Longitude", unit: "°" },
		{ key: "iridium_cep", label: "Cep", unit: "°" },

	];

	return (
		<Paper>
			<TableContainer>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell
								align="center"
								sx={{
									border: "1px solid #333333",
									position: "sticky",
									left: 0,
									zIndex: 1,
									backgroundColor: "#FFFFFF",
								}}
							>
								Timestamp
							</TableCell>
							{columnOrder.map((column) => (
								<TableCell
									key={column.key}
									align="center"
									sx={{ border: "1px solid #333333" }}
								>
									<Typography variant="body2" component="span">
										{column.label}
									</Typography>
									<Typography variant="caption" component="span">
										({column.unit})
									</Typography>
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{allData.map((row, index) => (
							<TableRow key={index}>
								<TableCell
									align="center"
									style={{ whiteSpace: "nowrap" }}
									sx={{
										border: "1px solid #333333",
										backgroundColor: "#FFFFFF",
										position: "sticky",
										left: 0,
										zIndex: 1,
									}}
								>
									{moment(row.timestamp).format("YYYY-MM-DD HH:mm:ss")}
								</TableCell>
								{columnOrder.map((column) => {
									const element = row.elements[column.key];
									return (
										<TableCell
											align="center"
											key={column.key}
											sx={{ border: "1px dotted #9c9898" }}
										>
											{element?.value !== undefined
												? element.qc_value !== undefined
													? formatValue(
															element.value,
															element.qc_value,
															element.comment
													  )
													: element.value.toFixed(2)
												: "-"}
										</TableCell>
									);
								})}
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				component="div"
				count={total_records}
				page={Math.floor(offset / limit)}
				onPageChange={handleChangePage}
				rowsPerPage={limit}
				onRowsPerPageChange={handleChangeRowsPerPage}
			/>
		</Paper>
	);
};

export default DataTable;
