import React, { useState, useEffect } from "react";
import {
	Box,
	Typography,
	Grid,
	Paper,
	IconButton,
	Tooltip,
	Alert,
	Modal,
	Skeleton,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import OpacityIcon from "@mui/icons-material/Opacity";
import AirIcon from "@mui/icons-material/Air";
import ThermostatIcon from "@mui/icons-material/Thermostat";
import CompressIcon from "@mui/icons-material/Compress";
import NavigationIcon from "@mui/icons-material/Navigation";
import WarningIcon from "@mui/icons-material/Warning";
import StationMap from "../StationMap/StationMap";
import moment from "moment";
import InfoIcon from "@mui/icons-material/Info";
import BatteryChargingFullIcon from '@mui/icons-material/BatteryChargingFull';
import SolarPowerIcon from '@mui/icons-material/SolarPower';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import {getCardinalDirection ,convertMSToKMH,getQCFlag, getStationMetadata} from '../../utils/rawFunction'
import WeatherInfoItem from './WeatherInfoItem'
import {stationMetaData} from '../../utils/rawData'

function WeatherDashboard({ data, stationId }) {
	const [openMap, setOpenMap] = useState(false);
	const [displayedValue, setDisplayedValue] = useState({
		value: 0,
		label: "Temperature",
		unit: "°C",
	});

	useEffect(() => {
		if (data && data.elements) {
			const { temperature_ext } = data.elements;
			setDisplayedValue({
				value: temperature_ext.value,
				label: "Temperature",
				unit: "°C",
			});
		}
	}, [data]);

	useEffect(() => {
		if (data && data.elements) {
			const interval = setInterval(() => {
				setDisplayedValue((prevValue) => {
					const weatherVariables = [
						{
							value: data.elements.temperature_ext.value,
							label: "Temperature",
							unit: "°C",
						},
						{
							value: data.elements.humidity_ext.value,
							label: "Humidity",
							unit: "%",
						},
						{
							value: data.elements.pressure_int.value,
							label: "Pressure",
							unit: "hPa",
						},
						{
							value: data.elements.wind_speed.value,
							label: "Wind Speed",
							unit: "km/h",
						},
					];
					const nextIndex =
						(weatherVariables.findIndex((v) => v.label === prevValue.label) +
							1) %
						weatherVariables.length;
					return weatherVariables[nextIndex];
				});
			}, 10000);

			return () => clearInterval(interval);
		}
	}, [data]);

	const handleMapOpen = () => {
		setOpenMap(true);
	};

	const handleMapClose = () => {
		setOpenMap(false);
	};

	if (!data || !data.elements) return null;

	const { timestamp, elements } = data;
	const metadata= getStationMetadata(stationId)
	const {
		temperature_int,
		humidity_int,
		pressure_int,
		temperature_ext,
		humidity_ext,
		pitch,
		roll,
		wind_speed,
		wind_direction,
		wind_gust_speed,
		wind_gust_direction,
		iridium_latitude,
		iridium_longitude,
		iridium_cep,
		voltage,
		solar,
		snow_depth
	} = elements;

  
	return (
		<Box sx={{ p: 3 }}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Paper sx={{ p: 3, color: "rgba(0, 0, 0, 0.8)", borderRadius: 4 }}>
						<Box
							sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}
						>
							<Box sx={{ display: "flex", alignItems: "center" }}>
								<IconButton
									color="inherit"
									size="small"
									onClick={handleMapOpen}
								>
									<LocationOnIcon />
								</IconButton>
								<Typography variant="subtitle1"> {metadata.name} ( {stationId} )</Typography>
							</Box>
							<Box sx={{ display: "flex", alignItems: "center" }}>
								<Typography variant="subtitle2">
									Last updated at {moment(timestamp).fromNow()}
								</Typography>
								<Tooltip
									title={`Exact time: ${moment(timestamp).format(
										"MMMM Do YYYY, h:mm:ss a"
									)}`}
								>
									<InfoIcon sx={{ ml: 1 }} />
								</Tooltip>
							</Box>
						</Box>
						<Box
							sx={{
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
								mb: 2,
							}}
						>
							<Box>
								<Typography
									variant="h1"
									sx={{ fontSize: "6rem", fontWeight: "bold" }}
								>
									{displayedValue.value.toFixed(1)}
									<span style={{ fontSize: "1rem", verticalAlign: "super" }}>
										{displayedValue.unit}
									</span>
								</Typography>
								<Typography variant="h4">{displayedValue.label}</Typography>
							</Box>
						</Box>

						<Typography variant="h6" sx={{ mb: 2 }}>
							Weather Info
						</Typography>

						<Grid container spacing={2}>
							<Grid item xs={6} sm={4} md={3}>
								<WeatherInfoItem
									label="Temperature Int"
									value={temperature_int.value}
									unit="°"
									icon={ThermostatIcon}
									element={temperature_int}
								/>
							</Grid>
							<Grid item xs={6} sm={4} md={3}>
								<WeatherInfoItem
									label="Temperature Ext"
									value={temperature_ext.value}
									unit="°"
									icon={ThermostatIcon}
									element={temperature_ext}
								/>
							</Grid>
							<Grid item xs={6} sm={4} md={3}>
								<WeatherInfoItem
									label="Humidity Int"
									value={humidity_int.value}
									unit="%"
									icon={OpacityIcon}
									element={humidity_int}
								/>
							</Grid>
							<Grid item xs={6} sm={4} md={3}>
								<WeatherInfoItem
									label="Humidity Ext"
									value={humidity_ext.value}
									unit="%"
									icon={OpacityIcon}
									element={humidity_ext}
								/>
							</Grid>
							<Grid item xs={6} sm={4} md={3}>
								<WeatherInfoItem
									label="Pressure Int"
									value={pressure_int.value}
									unit=" hPa"
									icon={CompressIcon}
									element={pressure_int}
								/>
							</Grid>
							<Grid item xs={6} sm={4} md={3}>
								<WeatherInfoItem
									label="Wind Speed"
									value={convertMSToKMH(wind_speed.value)}
									unit=" km/h"
									icon={AirIcon}
									element={wind_speed}
								/>
							</Grid>
							<Grid item xs={6} sm={4} md={3}>
								<WeatherInfoItem
									label="Wind Direction"
									value={wind_direction.value}
									unit="°"
									icon={NavigationIcon}
									element={wind_direction}
									rotateIcon={true}
								/>
							</Grid>
							<Grid item xs={6} sm={4} md={3}>
								<WeatherInfoItem
									label="Wind Gust Speed"
									value={convertMSToKMH(wind_gust_speed.value)}
									unit=" km/h"
									icon={AirIcon}
									element={wind_gust_speed}
								/>
							</Grid>
							<Grid item xs={6} sm={4} md={3}>
								<WeatherInfoItem
									label="Wind Gust Direction"
									value={wind_gust_direction.value}
									unit="°"
									icon={NavigationIcon}
									element={wind_gust_direction}
									rotateIcon={true}
								/>
							</Grid>
							<Grid item xs={6} sm={4} md={3}>
								<WeatherInfoItem
									label="Pitch"
									value={pitch.value}
									unit="°"
									icon={NavigationIcon}
									element={pitch}
									rotateIcon={true}
								/>
							</Grid>
							<Grid item xs={6} sm={4} md={3}>
								<WeatherInfoItem
									label="Roll"
									value={roll.value}
									unit="°"
									icon={NavigationIcon}
									element={roll}
									rotateIcon={true}
								/>
							</Grid>

							<Grid item xs={6} sm={4} md={3}>
								<WeatherInfoItem
									label="Voltage"
									value={voltage.value}
									unit=" V"
									icon={BatteryChargingFullIcon}
									element={voltage}
								/>
							</Grid>
							{solar?.value && <Grid item xs={6} sm={4} md={3}>
								<WeatherInfoItem
									label="Solar"
									value={solar.value}
									unit=" W/m²"
									icon={SolarPowerIcon}
									element={solar}
								/>
							</Grid>}
							
							{snow_depth?.value && <Grid item xs={6} sm={4} md={3}>
								<WeatherInfoItem
									label="Snow Depth"
									value={snow_depth.value}
									unit="°"
									icon={AcUnitIcon}
									element={snow_depth}
								/>
							</Grid>}
						</Grid>
					</Paper>
				</Grid>
			</Grid>
			<Modal
				open={openMap}
				onClose={handleMapClose}
				aria-labelledby="map-modal-title"
				aria-describedby="map-modal-description"
			>
				<Box
					sx={{
						position: "absolute",
						top: "50%",
						left: "50%",
						transform: "translate(-50%, -50%)",
						width: "80%",
						maxWidth: 600,
						bgcolor: "background.paper",
						boxShadow: 24,
						p: 4,
						borderRadius: 2,
					}}
				>
					<StationMap
						stationId={stationId}
						latitude={iridium_latitude.value}
						longitude={iridium_longitude.value}
						cep={iridium_cep.value}
						metadata={metadata}
					/>
				</Box>
			</Modal>
		</Box>
	);
}

export default WeatherDashboard;
