export const stationMetaData = [
    {
        id: "MPC",
        name: "Innaarulaalikuluup Nuvua",
        Community: "Arctic Bay",
        status: "Operational",
        type: "Automatic Weather Station",
        location: "North Articbay",
    },
    {
        id: "TST",
        name: "Experimental Farm",
        Community: "Ottawa",
        status: "Operational",
        type: "Tracking Beacons",
        location: "Ottawa",
    },
    {
        id: "DTEST",
        name: "Random 3",
        Community: "Igloolik",
        status: "Non Operational",
        type: "Automatic Weather Station",
        location: "North Articbay",
    },
    {
        id: "LOA",
        name: "Taqqajaat",
        Community: "Pond Inlet",
        status: "Operational",
        type: "Automatic Weather Station",
        location: "North Articbay",
    },
    {
        id: "ALW",
        name: "Aqiggilik",
        Community: "Arctic Bay",
        status: "Operational",
        type: "Tracking Beacons",
        location: "North Articbay",
    },
    {
        id: "TDY",
        name: "Ivisaaruqtuuq",
        Community: "Igloolik",
        status: "Operational",
        type: "Sea Ice Monitoring",
        location: "North Articbay",
    },
    {
        id: "UBY",
        name: "Kapuiviit Nuvua",
        Community: "Igloolik",
        status: "Operational",
        type: "Automatic Weather Station",
        location: "North Articbay",
    },
    {
        id: "OEG",
        name: "Qalirusiq",
        Community: "Igloolik",
        status: "Operational",
        type: "Automatic Weather Station",
        location: "North Articbay",
    },
    {
        id: "LVY",
        name: "Kalliraaq",
        Community: "Igloolik",
        status: "Operational",
        type: "Automatic Weather Station",
        location: "North Articbay",
    },
    {
        id: "ZTJ",
        name: "Nuvuit",
        Community: "Igloolik",
        status: "Operational",
        type: "Sea Ice Monitoring",
        location: "North Articbay",
    },
    {
        id: "FON",
        name: "Milne Ice Shelf",
        Community: "Milne Fiord",
        status: "Warning",
        type: "Automatic Weather Station",
        location: "North Articbay",
    },
    {
        id: "VUU",
        name: "Qikiqtatannak",
        Community: "Arctic Bay",
        status: "Operational",
        type: "Automatic Weather Station",
        location: "North Articbay",
    },
    {
        id: "NPK",
        name: "Purple Valley",
        Community: "Milne Fiord",
        status: "Operational",
        type: "Sea Ice Monitoring",
        location: "North Articbay",
    },
    {
        id: "YBL",
        name: "Naujaaruluit",
        Community: "Igloolik",
        status: "Operational",
        type: "Tracking Beacons",
        location: "North Articbay",
    },
];