import React from 'react';
import { Typography, Box, Grid, Paper, IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ReactCountryFlag from "react-country-flag";
import { useMediaQuery } from '@mui/material';
import AllStationsList from '../AllStations/AllStationsList';
import AllStationHomeList from '../AllStations/AllStationHomeList';


function Home() {
  return (
    <>
    <Box sx={{ p: 2, maxWidth: '70%', margin: 'auto', overflow: 'auto' }}>
     <AllStationHomeList/>
    </Box>
         </>
  );
}

export default Home;